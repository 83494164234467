import Lottie from "lottie-react";
import locationPinFlat from "../assets/lottie/location-pin-flat.json"

export default function Loading({ id, title, message, loading, schema }) {

  const isLoading = loading === 'true'

  const { logo } = schema.global

  return (
    <div className='container mx-auto max-w-xl'>
      <div className='flex flex-col items-center justify-center h-screen'>
        {isLoading &&
          <>
            <Lottie className="h-32 mt-4" animationData={locationPinFlat} />
            <p className='text-md font-bold text-iceland-red' id={id}>{title}</p>
            <span className="border-t border-gray-300 w-64 m-6"></span>
          </>
        }
        <img className="h-6 w-auto mb-6" src={logo} alt="Iceland Logo" />
        <div className="flex flex-col items-center text-center mt-6 max-w-sm">
          {!isLoading &&
            <>
              <h1 className='text-2xl font-bold text-iceland-red' id={id}>{title}</h1>
              <span className="border-t border-gray-300 w-64 m-4"></span>
              <p className='text-md text-iceland-red'>{message}</p>
              <p className='text-md text-slate-500 tracking-tight mt-6'>Please visit the <a className='text-cyan-600 underline underline-offset-4' id="account-link" href="https://www.iceland.co.uk/account">My Account</a> area of our website for the most up to date information on your order(s).</p>
            </>
          }
        </div>
      </div>
    </div>
  );
}
