import logo from '../assets/logo.svg';

export const Default = {
   global: {
      title: "Delivery Tracker | ICELAND",
      logo,
   },
   "welcome_card": {
      "enable": true,
      "morning_title": "Good morning, {customer_name}",
      "afternoon_title": "Good afternoon, {customer_name}",
      "evening_title": "Good evening, {customer_name}",
      "morning_img_src": "",
      "afternoon_img_src": "",
      "evening_img_src": "",
      "welcome_status_update": {
         "in_progress": "Your chosen delivery slot is between {slot_start_time} and {slot_end_time} on {slot_delivery_date}",
         "completed": "It looks like our driver successfully delivered your order",
         "failed": "It looks like our driver couldn't deliver your order",
         "cancelled": "It looks like you have cancelled your delivery"
      }
   },
   "tracking_card": {
      "enable": true,
      "refresh_interval": 60,
      "icon_img_src": "",
      "live_tracking_feature": {
         "enable": true,
         "title": "Live Tracking",
         "refresh_message": "Last updated at {time}",
         "icon_img_src": ""
      },
      "in_progress": {
         "title": {
            "next_status": "We're heading your way!",
            "last_status": "Just {stops} stop away!",
            "status": "We're {stops} stops away!"
         },
         "subtitle": "We hope to deliver to you at approximately {eta}",
         "icon_img_src": ""
      },
      "completed": {
         "title": "We've delivered your order",
         "subtitle": "Your order was successfully delivered today at {delivery_time}",
         "icon_img_src": ""
      },
      "cancelled": {
         "title": "Delivery cancelled",
         "subtitle": "You've cancelled your delivery",
         "icon_img_src": ""
      },
      "failed": {
         "title": "We couldn't reach you",
         "subtitle": "{reason}",
         "icon_img_src": ""
      }
   },
   "status_card": {
      "enable": true,
      "pending_status": "Placed",
      "in_progress_status": "Out for Delivery",
      "completed_status": "Delivered",
      "cancelled_status": "Cancelled",
      "failed_status": "Failed"
   },
   "courier_card": {
      "enable": true,
      "icon_img_src": "",
      "in_progress": {
         "title": "Your driver today is {driver_name}",
         "subtitle": "Vehicle registration {reg_number}"
      },
      "completed": {
         "title": "Your driver delivered your order",
         "subtitle": "Vehicle registration {reg_number}"
      },
      "failed": {
         "title": "Your driver can try to reattempt",
         "subtitle": "Vehicle registration {reg_number}",
         "advice": "Please contact your store below to discuss redelivery of your order."

      }
   },
   "contact_card": {
      "enable": true,
      "in_progress": {
         "title": "Problem with your delivery",
         "subtitle": "If you experience any issues with your delivery please call your store below",
         "contact_panel": {
            "title": "{store_name}",
            "phone_number": "{store_number}"
         }
      },
      "completed": {
         "title": "Problem with your order",
         "subtitle": "If you experience any issues with your delivery please call our customer service team below.",
         "contact_panel": {
            "title": "Iceland Customer Service",
            "phone_number": "0800 328 0800"
         }
      }
   }
}