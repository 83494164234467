import { useState, useEffect } from 'react'
import { Default } from './default'

export const useSchema = (initialValue = Default) => {

    const BACKEND_URL = "http://cloud-cloud-1f70y15z0ro2a-236285120.eu-west-1.elb.amazonaws.com"
    const getAdminSchema = async () => {

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer xxxx");

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const request = await fetch(
            `${BACKEND_URL}/api/epod-delivery-tracker?populate=deep`,
            requestOptions
        )

        const response = await request.json()

        return response.data.attributes
    }

    const generateBySchema = (schema, source) => {
        const jsonSchema = schema
        for (const key of Object.keys(jsonSchema)) {

            if (source[key] === undefined) {
                continue;
            }

            if (jsonSchema[key].constructor.name === 'Object') {
                generateBySchema(jsonSchema[key], source[key])
            } else {
                if (typeof jsonSchema[key] === typeof source[key]) {
                    jsonSchema[key] = (source[key] !== null || source !== "") && source[key]
                } else {

                    // Custom conditional API handling

                    // Strapi Media URL
                    if (typeof source[key] === 'object' && source[key]?.data) {
                        jsonSchema[key] = BACKEND_URL + source[key].data.attributes.url
                    }
                }
            }
        }
        return jsonSchema
    }

    const [schema, setSchema] = useState(initialValue)

    useEffect(() => {
        (async () => {
            let allowExternalAPI = false
            const destructObject = allowExternalAPI ? await getAdminSchema() : Default
            const apiSchema = generateBySchema(Default, destructObject)
            setSchema(apiSchema)
        })();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return schema

}