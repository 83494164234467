import { Status, handlebar } from "../utils/utils";

const DriverCard = ({ order, schema, status }) => {

    function capitalize(str) {
        if (str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        } else {
            return str;
        }
    }

    const driversName = capitalize(order.drivers_name)

    return (
        <>
            <div className="flex items-center bg-white rounded-md shadow-even p-1 mb-4 opacity-0 animate-fadeInUp-3">
                <div className="m-2 sm:m-4 w-16 h-16 sm:w-24 sm:h-24 bg-gray-200 bg-driver-avatar bg-[length:100px] sm:bg-[length:120px] bg-top bg-no-repeat rounded-lg"></div>
                <div className="m-2 sm:m-4 rounded-md">
                    <h1 className="text-md sm:text-2xl text-gray-800 leading-tight font-semibold">{handlebar(schema[status].title, "driver_name", driversName)}</h1>
                    <p className="text-xs sm:text-lg text-gray-500">{handlebar(schema[status].subtitle, "reg_number", order.delivery_vehicle_reg)}</p>
                </div>
            </div>
            {order.delivery_status === Status.FAILED &&
                <div className="flex items-center bg-white rounded-md shadow-even p-1 mb-4 opacity-0 animate-fadeInUp-3">
                    <div className="m-2 sm:m-4 rounded-md">
                        <p className="text-xs sm:text-lg text-gray-500">{schema[status].advice || "Please contact "}</p>
                    </div>
                </div>
            }
        </>
    )
}

export default DriverCard;