import { Status } from '../utils/utils';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDot, faCircleCheck, faCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";



const StatusCard = ({ order, schema, status }) => {

    let isCompleted = order.delivery_status === Status.COMPLETED;

    return (
        <>
            <div className="flex items-center justify-around justify-items-center relative bg-white rounded-md shadow-even p-4 mb-4 opacity-0 animate-fadeInUp-2">

                <div className="flex flex-col items-center w-16">
                    <FontAwesomeIcon className="w-6 h-6 sm:w-8 sm:h-8 text-iceland-red" icon={faCircleCheck} />
                    <span className='text-xs sm:text-sm'>{schema.pending_status}</span>
                </div>

                <div className="flex grow -mt-2.5 sm:-mt-5">
                    <div className="flex flex-col items-center translate-x-3">
                        <div className="rounded-full h-2 w-2 sm:h-3 sm:w-3 bg-iceland-red"></div>
                    </div>
                    <div className="mt-0.5 sm:mt-1.5 m-1.5 rounded-lg bg-gradient-to-r from-iceland-red to-iceland-red h-1 sm:h-0.5 w-full">&nbsp;</div>
                    <div className="flex flex-col items-center -translate-x-3">
                        <div className="rounded-full h-2 w-2 sm:h-3 sm:w-3 bg-iceland-red"></div>
                    </div>
                </div>

                {status === 'failed' ?
                    (<div className="flex flex-col items-center -mx-8 sm:-mx-8 px-8 sm:px-10">
                        <FontAwesomeIcon className="w-6 h-6 sm:w-8 sm:h-8 text-iceland-red" icon={faCircleXmark} />
                        <span className='text-xs sm:text-sm'>{schema.failed_status}</span>
                    </div>)
                    :
                    (<div className="flex flex-col items-center -mx-8 sm:-mx-8">
                        <FontAwesomeIcon className={"w-6 h-6 sm:w-8 sm:h-8 text-iceland-red " + (isCompleted ? "" : "animate-grow")} icon={isCompleted ? faCircleCheck : faCircleDot} />
                        <span className='text-xs sm:text-sm'>{schema.in_progress_status}</span>
                    </div>)
                }

                <div className="flex grow -mt-2.5 sm:-mt-5">
                    <div className="flex flex-col items-center translate-x-3">
                        <div className="rounded-full h-2 w-2 sm:h-3 sm:w-3 bg-iceland-red"></div>
                    </div>
                    <div className={"mt-0.5 sm:mt-1.5 m-1.5 rounded-lg bg-gradient-to-r from-iceland-red h-1 sm:h-0.5 w-full " + (isCompleted ? "to-iceland-red" : "to-white")}>&nbsp;</div>
                    <div className="flex flex-col items-center -translate-x-3">
                        <div className={(isCompleted ? "bg-iceland-red" : "") + " rounded-full h-2 w-2 sm:h-3 sm:w-3"}></div>
                    </div>
                </div>

                <div className="flex flex-col items-center w-16">
                    <FontAwesomeIcon className={isCompleted ? "w-6 h-6 sm:w-8 sm:h-8 text-iceland-red" : "w-2 h-2 sm:w-4 sm:h-4 text-gray-300 p-2"} icon={isCompleted ? faCircleCheck : faCircle} />
                    <span className={"text-xs sm:text-sm " + (isCompleted ? "text-iceland-red" : "text-gray-300")}>{schema.completed_status}</span>
                </div>

            </div>
        </>
    )
}

export default StatusCard;