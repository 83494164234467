import AppProvider from './contexts/AppContext'
import { App } from './App';

import { useSchema } from './locale/schema'

export const PageInit = ({
  t = new URLSearchParams(window.location.search).get('t') || ''
}) => {

  const schema = useSchema();

  if (!schema)
    console.log('Sorry, schema is currently unavailable');

  return (
    <AppProvider id={t}>
      <App schema={schema} />
    </AppProvider>
  )
} 