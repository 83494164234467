import { handlebar } from "../utils/utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const ContactCard = ({ customer, schema, status }) => {
    const fNumber = customer.store_phone.replace(/^(\d{5})(\d{3})(\d{3})$/, '$1 $2 $3')
    const SCHEMA_STATUS = status === 'completed' ? 'completed' : 'in_progress'

    return (
        <>
            <div className="flex relative border-t border-gray-300 m-6 sm:m-10 mx-16"></div>

            <div className='block mb-6 opacity-0 animate-fadeInUp-4'>
                <h1 className="text-md sm:text-3xl text-gray-800 font-semibold">{schema[SCHEMA_STATUS].title}</h1>
                <p className="text-sm sm:text-base text-gray-500 w-5/6">{schema[SCHEMA_STATUS].subtitle}</p>
            </div>

            <div className="flex items-center justify-between bg-white rounded-md shadow-even p-1 mb-10 opacity-0 animate-fadeInUp-5">
                <div className="m-2 sm:m-4 rounded-md">
                    <h1 className="text-md sm:text-2xl text-gray-800 capitalize leading-tight font-semibold">{handlebar(schema[SCHEMA_STATUS].contact_panel.title, "store_name", customer.store_name)}</h1>
                    <p className="text-sm sm:text-lg text-gray-500">{handlebar(schema[SCHEMA_STATUS].contact_panel.phone_number, "store_number", fNumber)}</p>
                </div>
                <a className="flex items-center justify-center m-2 sm:m-4 w-16 h-16 sm:w-20 sm:h-20 border bg-white rounded-lg" id="contact-link" href={"tel:" + handlebar(schema[SCHEMA_STATUS].contact_panel.phone_number, "store_number", fNumber)}>
                    <FontAwesomeIcon icon={faPhone} className="text-gray-800 text-xl sm:text-5xl w-6 h-6 sm:w-10 sm:h-10" />
                </a>
            </div>
        </>
    )
}

export default ContactCard;