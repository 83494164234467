import Lottie from "lottie-react";
import { Status, handlebar, convertDateTime, setFailedDeliveryMessage } from '../utils/utils';

import FaFinishFlag from "../assets/svg/flag-finish-icon.svg";
import confettiFlat from "../assets/lottie/confetti-flat.json";
import trackingPinFlat from "../assets/lottie/tracking-pin.json";
import failedFlat from "../assets/lottie/failed-flat.json"

const LiveTrackingFeature = ({ schema, refresh_at }) => {

    const update_at = convertDateTime(refresh_at)

    return (
        <div className='flex items-center justify-center bg-white rounded-xl shadow-even p-2'>
            <Lottie className="w-10 sm:w-12" animationData={trackingPinFlat} />
            <div className='text'>
                <h3 className='text-md sm:text-xl leading-none text-gray-800 font-semibold'>{schema.title}</h3>
                <span className='text-xs sm:text-sm leading-none text-gray-500'>{handlebar(schema.refresh_message, "time", update_at.time)}</span>
            </div>
        </div>
    )
}

const GoogleMaps = ({ order, schema, status, last_updated }) => {

    const stops = order.delivery_stop - order.jobs_attempted - 1;
    const deliveredAt = convertDateTime(order.delivery_date + "T" + order.delivery_time.substring(0, 5))
    const eta = convertDateTime(order.delivery_date + "T" + order.estimated_delivery_time)

    return (
        <div className="flex flex-col bg-white rounded-md shadow-even p-1 mb-4 opacity-0 animate-fadeInUp-1">
            <div className="relative w-auto rounded-md">

                {order.delivery_status === Status.INPROGRESS &&
                    <>
                        <div className="absolute top-10 max-w-max top-14 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4/5 sm:w-3/5 mx-auto">
                            {schema.live_tracking_feature.enable && <LiveTrackingFeature schema={schema.live_tracking_feature} refresh_at={last_updated} />}
                        </div>

                        <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                            <span className="animate-radar absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                            <span className='flex relative w-24 h-24 bg-van-icon bg-contain bg-no-repeat z-index-1'></span>
                        </div>

                        <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full">
                            <div className="flex flex-col" id="tracking">
                                <div className='absolute self-center flex items-center justify-center bg-white w-12 h-12 sm:w-16 sm:h-16 z-20 rounded-full'>
                                    <img
                                        className="block h-6 sm:h-8 w-auto"
                                        src={FaFinishFlag}
                                        alt="delivery flag"
                                    />
                                </div>
                                <span className="self-center bg-white shadow-even w-14 h-14 sm:w-16 sm:h-16 -mb-6 rounded-full"></span>
                                <div className='bg-white rounded-xl shadow-even p-2 py-3 sm:p-4 z-10 mx-auto max-w-sm'>
                                    <h1 className='text-md sm:text-2xl text-iceland-red text-center font-semibold'>
                                        {stops === 0 && handlebar(schema[status].title.next_status, "stops", stops)}
                                        {stops === 1 && handlebar(schema[status].title.last_status, "stops", stops)}
                                        {stops >= 2 && handlebar(schema[status].title.status, "stops", stops)}
                                    </h1>
                                    <p className='text-xs sm:text-lg text-gray-800 text-center leading-tight'>{handlebar(schema[status].subtitle, "eta", eta.time)}</p>
                                </div>
                            </div>
                        </div>

                        <img className="w-full aspect-square object-cover rounded-md" src={order.map_image} alt="Google Maps" />
                    </>
                }
                {order.delivery_status === Status.COMPLETED &&
                    <div className="w-full object-cover rounded-md" id="completed">
                        <Lottie className="h-32 mt-4" animationData={confettiFlat} />
                        <div className='bg-white rounded-xl p-2 sm:p-4 z-10 mx-auto w-3/4 sm:w-1/2 w-[200px] sm:w-3/4'>
                            <h1 className='text-md sm:text-2xl text-iceland-red text-center font-semibold'>{schema[status].title}</h1>
                            <p className='text-xs sm:text-lg text-gray-800 text-center leading-tight'>{handlebar(schema[status].subtitle, "delivery_time", deliveredAt.time)}</p>
                        </div>
                    </div>
                }
                {order.delivery_status === Status.FAILED &&
                    <div className="w-full object-cover rounded-md" id="failed">
                        <Lottie className="h-32 mt-4" animationData={failedFlat} />
                        <div className='bg-white rounded-xl p-2 sm:p-4 z-10 mx-auto w-3/4 sm:w-1/2 w-[200px] sm:w-3/4'>
                            <h1 className='text-md sm:text-2xl text-iceland-red text-center font-semibold'>{schema[status].title}</h1>
                            <p className='text-xs sm:text-lg text-gray-800 text-center leading-tight'>{setFailedDeliveryMessage(order.delivery_status_reason)}</p>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default GoogleMaps;