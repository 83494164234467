import { createContext, useState } from 'react';

export const LocalStorageContext = createContext();

export const LocalStorageProvider = ({ children }) => {
    const STORAGE_NAME = 'verification_data'

    const [storage, setStorage] = useState(
        JSON.parse(localStorage.getItem(STORAGE_NAME)) || {}
    );

    const addValue = (key, value) => {
        setStorage({ ...storage, [key]: value });
        localStorage.setItem(STORAGE_NAME, JSON.stringify({ ...storage, [key]: value }));
    };

    const removeValue = (key) => {
        const newData = { ...storage };
        delete newData[key];
        setStorage(newData);
        localStorage.setItem(STORAGE_NAME, JSON.stringify(newData));
    };

    const addValues = (values) => {
        const newData = { ...storage, ...values };
        setStorage(newData);
        localStorage.setItem('verification_data', JSON.stringify(newData));
    };

    const removeValues = (keys) => {
        const newData = { ...storage };
        keys.forEach((key) => delete newData[key]);
        setStorage(newData);
        localStorage.setItem(STORAGE_NAME, JSON.stringify(newData));
    };

    const clearValues = () => {
        localStorage.removeItem('verification_data');
    }

    return (
        <LocalStorageContext.Provider value={{ storage, addValue, removeValue, addValues, removeValues, clearValues }}>
            {children}
        </LocalStorageContext.Provider>
    );
};


export default LocalStorageProvider;