import './App.css';

import { useContext } from 'react';
import { AppContext } from './contexts/AppContext';
import { LocalStorageContext } from './contexts/LocalStorage'

import { API } from './components/API';
import Nav from './components/Nav';
import Loading from './components/Loading';
import WelcomeCard from './components/WelcomeCard';
import GoogleMaps from './components/GoogleMaps';
import StatusCard from './components/StatusCard';
import DriverCard from './components/DriverCard';
import ContactCard from './components/ContactCard';

import { getSchemaStatus } from './utils/utils';
import ErrorBoundary from './utils/errorHandling'

export const App = ({ schema }) => {

  const { global, order, customer } = useContext(AppContext);
  const { storage } = useContext(LocalStorageContext)

  const schemaStatus = getSchemaStatus(order.delivery_status)

  const loadingCard = <Loading id='loading' title='Locating order' loading='true' schema={schema} />
  const pendingCard = <Loading id='pending' title='Tracking Available Soon' message="Your order has not yet been loaded onto our delivery vehicle, please check back later." loading='false' schema={schema} />
  const errorCard = <Loading id='error' title='GPS signal lost' message="Refresh page to track your delivery again." loading='false' schema={schema} />
  const expiredCard = <Loading id='expired' title='Order URL expired' message="We're unable to offer live tracking for an expired order at this time." loading='false' schema={schema} />
  const invalidCard = <Loading id='invalid' title='Invalid or no order found' message='Please check your text message or your e-mail for a valid order tracking URL.' loading='false' schema={schema} />

  if (!global.id || global.id === '')
    return invalidCard

  return (
    <>
      <ErrorBoundary storage={storage} errorCard={errorCard} expiredCard={expiredCard}>
        <API loadingCard={loadingCard} pendingCard={pendingCard} expiredCard={expiredCard} invalidCard={invalidCard}>
          <Nav schema={schema.global} />
          <div className='container mx-auto max-w-xl animate-grow -mt-10 px-2 sm:px-0'>
            <div className='flex flex-col'>
              {schema.welcome_card.enable && <WelcomeCard order={order} customer={customer} schema={schema.welcome_card} status={schemaStatus} />}
              {schema.tracking_card.enable && <GoogleMaps order={order} schema={schema.tracking_card} status={schemaStatus} last_updated={global.lastUpdated} />}
              {schema.status_card.enable && <StatusCard order={order} schema={schema.status_card} status={schemaStatus} />}
              {schema.courier_card.enable && <DriverCard order={order} schema={schema.courier_card} status={schemaStatus} />}
              {schema.contact_card.enable && <ContactCard order={order} customer={customer} schema={schema.contact_card} status={schemaStatus} />}
            </div>
          </div>
        </API>
      </ErrorBoundary>
    </>
  );
}
