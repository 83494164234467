import { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children, id }) => {
    const [global, setGlobal] = useState({
        id,
        isLoading: true,
        isExpired: false,
        lastUpdated: new Date()
    });
    const [order, setOrder] = useState({ delivery_status: false });
    const [customer, setCustomer] = useState({});
    const [content, setContent] = useState({});

    const updateGlobal = (key, value) => setGlobal(prevState => ({ ...prevState, [key]: value }));
    const updateOrder = (order) => setOrder(order);
    const updateCustomer = (customer) => setCustomer(customer);
    const updateContent = (content) => setContent(content);

    return (
        <AppContext.Provider
            value={{
                global,
                order,
                customer,
                content,
                updateGlobal,
                updateOrder,
                updateCustomer,
                updateContent
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;