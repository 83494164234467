import React from 'react';
import { createRoot } from 'react-dom/client';
import LocalStorageProvider from './contexts/LocalStorage';
import { PageInit } from './PageInit'
import './index.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <LocalStorageProvider>
      <PageInit />
    </LocalStorageProvider>
  </React.StrictMode>
)