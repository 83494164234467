import { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, hasExpired: false };
    }

    static getDerivedStateFromError(error) {
        console.log("error handling err", error);
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        if (this.state.hasError) {
            console.log("Error boundary detected error...");
            return (
                <>
                    {this.props.errorCard}
                </>
            );
        } else if (this.state.hasExpired) {
            return this.props.expiredCard;

        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;