export const Status = {
  PENDING: 'Pending',
  INPROGRESS: 'Out for delivery',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  CANCELLED: 'cancelled'
}

export const getSchemaStatus = (status) => {
  switch (status) {
    case Status.PENDING:
      return 'pending';
    case Status.INPROGRESS:
      return 'in_progress'
    case Status.COMPLETED:
      return 'completed'
    case Status.FAILED:
      return 'failed'
    default:
      return 'default';
  }
}

export const setFailedDeliveryMessage = (failedStatus) => {

  switch (failedStatus) {
    case "No access to premises":
      return "We couldn't access your premises";
    case "Delivery cancelled by customer":
      return "We're sorry you cancelled your order";
    default:
      return "We couldn't deliver your order today";
  }
}

export const handlebar = (m, h, t) => {
  let x = m;

  const hasHandles = x.match(/{.*?}/g) !== null;
  if (!hasHandles)
    return x;

  if (typeof h === typeof []) {
    for (let i = 0; i < h.length; i++) {
      x = x.includes(`{${h[i]}}`) && x.replace(`{${h[i]}}`, t[i])
    }
  } else {
    x = x.includes(`{${h}}`) ? x.replace(`{${h}}`, t) : x.replaceAll(/{.*?}/g, '');
  }

  return x
}

export const convertDateTime = (datetime) => {
  let date, time

  if (datetime) {
    const timestamp = Date.parse(datetime)
    const datum = new Date(timestamp);

    date = new Intl.DateTimeFormat('default', { dateStyle: 'full' }).format(datum)
    time = new Intl.DateTimeFormat('default', { timeStyle: 'short', hourCycle: 'h12' }).format(datum)

    return { date, time }
  }
}

export const hasOrderExpired = (date) => {
  const today = new Date(Date.now());
  const orderDate = new Date(date);

  if (orderDate.getTime()) {
    if (orderDate.getFullYear() !== today.getFullYear() ||
      orderDate.getMonth() !== today.getMonth() ||
      orderDate.getDate() !== today.getDate()
    ) {
      return true
    }
    return false
  }
  return false
};