import { handlebar, convertDateTime } from "../utils/utils";

const WelcomeCard = ({ order, customer, schema, status }) => {

  const setDayPeriod = (date = false) => {
    const hour = date || new Date().getHours();

    if (hour >= 5 && hour < 12) return 'morning';
    if (hour >= 12 && hour < 17) return 'afternoon';
    if (hour >= 17 && hour < 24) return 'evening';
  }

  const startDatum = convertDateTime(customer.window_start_time)
  const endDatum = convertDateTime(customer.window_end_time)

  return (
    <div className="flex flex-col bg-white rounded-md shadow-even p-1 mb-4 opacity-0 animate-fadeInUp">
      <span className="hidden bg-morning-icon bg-afternoon-icon bg-evening-icon bg-night-icon"></span>
      <div className={`w-auto rounded-md bg-25 bg-right bg-no-repeat bg-${setDayPeriod()}-icon p-2 sm:p-4 pr-0`}>
        <h1 className="text-xl sm:text-3xl text-gray-800 font-semibold">
          {handlebar(schema[`${setDayPeriod()}_title`], "customer_name", customer.first_name)}
        </h1>
        <p className="text-sm sm:text-lg text-gray-500 w-full sm:w-5/6">
          {handlebar(schema.welcome_status_update[status], ["slot_start_time", "slot_end_time", "slot_delivery_date"], [startDatum.time, endDatum.time, endDatum.date])}</p>
      </div>
    </div>
  )
}

export default WelcomeCard;